import './style.css'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { TrackballControls } from 'three/examples/jsm/controls/TrackballControls.js'
import { PointerLockControls } from 'three/examples/jsm/controls/PointerLockControls.js'

import * as dat from 'dat.gui'

import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js'
import { SavePass } from 'three/examples/jsm/postprocessing/SavePass.js'
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js'
import { GlitchPass } from 'three/examples/jsm/postprocessing/GlitchPass.js'
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass.js'

import { BlendShader } from 'three/examples/jsm/shaders/BlendShader.js'
import { CopyShader } from 'three/examples/jsm/shaders/CopyShader.js'

import { SVGLoader } from 'three/examples/jsm/loaders/SVGLoader.js'

// Vars 

const mapAccellarator = 0.1; //0.5
const meshAccellarator = 4.0; //4.0
const motionBlurStrength = 0.9; //0.9

const logo = document.querySelector('img.logo');
var newImg = new Image;
newImg.onload = function() {
    logo.src = this.src;
}
newImg.src = './thefriends-logo-white.svg';

// Debug
// const gui = new dat.GUI()

// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()
scene.background = new THREE.Color(0x000000);

// Objects
// const geometry = new THREE.TorusGeometry( .7, .2, 16, 100 );
const geometry = new THREE.TorusGeometry(40.0, 10.0, 30, 200);

// logo
const svgLoader = new SVGLoader();
svgLoader.load('./thefriends-logo-black.svg', (data) => {
    // const shapePaths = data.paths;
    addLogo(data)
});

const addLogo = (svg) =>
{
    const svgGroup = new THREE.Group();
    // const svgMaterial = new THREE.MeshNormalMaterial();
    // const svgMaterial = new THREE.MeshStandardMaterial({
    //     color: 0xffffff,
    //     side: THREE.DoubleSide,
    //     flatShading: false,
    // });
    const svgMaterial = new THREE.MeshPhysicalMaterial({
        color: 0xffffff,
        // shininess: 0,
        side: THREE.DoubleSide,
        // flatShading: false,
        reflectivity: 1.0,
    });

    svg.paths.forEach((path, i) => {
        const shapes = path.toShapes(true);

        // Each path has array of shapes
        shapes.forEach((shape, j) => {
            // Finally we can take each shape and extrude it
            const geometry = new THREE.ExtrudeGeometry(shape, {
                steps: 10,
                depth: 25,
                bevelEnabled: false
            });
            // patch
            geometry.applyMatrix4(new THREE.Matrix4().makeScale ( 1, -1, 1 ))
            // Create a mesh and add it to the group
            const mesh = new THREE.Mesh(geometry, svgMaterial);
            mesh.castShadow = false;
            mesh.receiveShadow = false;
            svgGroup.add(mesh);
        });
    });

    const svgScaler = 0.005
    svgGroup.scale.set(svgScaler,svgScaler,svgScaler)
    // svgGroup.position.z = 8

    new THREE.Box3().setFromObject( svgGroup ).getCenter( svgGroup.position ).multiplyScalar( - 1 )

    // scene.add( svgGroup )
}

// Materials

// const material = new THREE.MeshBasicMaterial()
// material.color = new THREE.Color(0xff0000)

const pointsMaterial = new THREE.PointsMaterial({
    size: 0.025,
    // map: new THREE.TextureLoader().load('https://media.istockphoto.com/photos/watercolor-painting-background-picture-id1179822347?k=20&m=1179822347&s=612x612&w=0&h=m1CTMzPt2ff7PxHrlPYejhg-UY1nrKcp1zlWEuZuaEw='),
})
// pointsMaterial.color = new THREE.Color(0xff0000)

// const material = new THREE.MeshPhysicalMaterial({})
// material.reflectivity = 0
// material.transmission = 1.0
// material.roughness = 0.2
// material.metalness = 0
// material.clearcoat = 0.3
// material.clearcoatRoughness = 0.25
// material.color = new THREE.Color(0xff0000)
// material.ior = 1.2
// material.thickness = 10.0

const textures = [
    './rainbow_texture665_90_sq.jpg',
    './rainbow_texture665_90_alpha_sq.jpg',
    './seamless-flecked-abstract-rainbow-texture-seamless-flecked-abstract-rainbow-texture-background-useful-website-design-mobile-132838444.jpg',
    './2352343.jpg',
    './2352343_alpha.jpg',
    './1000_F_354733529_EUqYHKVLIvm7JiOHOWWX7t4KNXMfahHe.jpg',
    './photo-1598942314842-4cb79089e4d5.jpg',
]

const rainbowTexture = new THREE.TextureLoader().load(textures[3]);
const rainbowTextureLight = new THREE.TextureLoader().load(textures[6]);
const rainbowTextureAlpha = new THREE.TextureLoader().load(textures[6]);

const materials = new THREE.MeshStandardMaterial({
    // shininess: 0,
    // needsUpdate: true,
    // matrixAutoUpdate: true,
    map: rainbowTexture,

    alphaMap: rainbowTextureAlpha,
    // alphaTest: 0.2,

    emissiveMap: rainbowTextureLight,
    emissiveIntensity: 2.0,

    lightMap: rainbowTextureLight,
    lightMapIntensity: 2.0,

    // bumpMap: new THREE.TextureLoader().load('https://threejs.org/examples/textures/water.jpg'),
    wireframe: false,
    // specular: 0x636e72, 
    // specular: 0xff0000, 
    flatShading: false,
    // color: 0xff0000

    transparent: true,
    side: THREE.DoubleSide, //THREE.DoubleSide
});
  
//add material setting
const material = new THREE.MeshPhysicalMaterial({
    clearcoat: 0,
    clearcoatRoughness: 0,
    metalness: 0,
    roughness: 0.9,
    // color: 0x8418ca,
    reflectivity: 0.5,
    //normalMap: texture,
    //normalScale: new THREE.Vector2(0.15,0.15),
    //envMap: envmap.texture
    // shininess: 0,
    // needsUpdate: true,
    // matrixAutoUpdate: true,
    map: rainbowTexture,

    alphaMap: rainbowTextureAlpha,
    // alphaTest: 0.2,

    emissiveMap: rainbowTextureLight,
    emissiveIntensity: 2.0,

    lightMap: rainbowTextureLight,
    lightMapIntensity: 2.0,

    // bumpMap: new THREE.TextureLoader().load('https://threejs.org/examples/textures/water.jpg'),
    wireframe: false,
    // specular: 0x636e72, 
    // specular: 0xff0000, 
    flatShading: false,
    // color: 0xff0000

    transparent: true,
    side: THREE.DoubleSide, //THREE.DoubleSide
    fog: true,
});

if(material.map){
    material.map.magFilter = THREE.NearestFilter;
    material.map.wrapT = THREE.RepeatWrapping;
    material.map.repeat.y = 1;
}
if(material.emissiveMap){
    material.emissiveMap.magFilter = THREE.NearestFilter;
    material.emissiveMap.wrapT = THREE.RepeatWrapping;
    material.emissiveMap.repeat.y = 1;
}
if(material.alphaMap){
    material.alphaMap.magFilter = THREE.NearestFilter;
    material.alphaMap.wrapT = THREE.RepeatWrapping;
    material.alphaMap.repeat.y = 1;
}
if(material.lightMap){
    material.lightMap.magFilter = THREE.NearestFilter;
    material.lightMap.wrapT = THREE.RepeatWrapping;
    material.lightMap.repeat.y = 1;
}

// Mesh
const sphere = new THREE.Mesh(geometry,material)
sphere.castShadow = false;
sphere.receiveShadow = false;
// const sphere = new THREE.Points(geometry,pointsMaterial)

if(window.innerWidth > 640){
    sphere.rotation.x = THREE.Math.degToRad( 45 );
    sphere.rotation.y = THREE.Math.degToRad( 40 );
}else{
    sphere.rotation.x = THREE.Math.degToRad( 90 );
    sphere.rotation.y = THREE.Math.degToRad( 50 );
}

sphere.position.y = 3;

scene.add(sphere)

// Lights

const pointLight = new THREE.PointLight(0xffffff, 1.0)
pointLight.position.set(10,20,-50)
// pointLight.position.set(-50, 100, 50);
pointLight.castShadow = false;
// pointLight.shadow.darkness = 0.5;
// pointLight.shadow.camera.visible = true;
scene.add(pointLight)

const ambientLight = new THREE.AmbientLight(0xffffff, 0.25);
ambientLight.castShadow = false;
scene.add(ambientLight);


/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)
camera.position.x = 0
camera.position.y = 0
camera.position.z = 2
scene.add(camera)

// Controls
// const controls = new OrbitControls(camera, canvas)
// controls.enableDamping = true
// controls.screenSpacePanning = true;
// controls.noZoom = true

// const controls = new TrackballControls( camera, canvas )
// controls.enabled = false
// controls.rotateSpeed = 1.0
// controls.zoomSpeed = 1.2
// controls.panSpeed = 0.8
// controls.keys = ['KeyA', 'KeyS', 'KeyD']
// controls.noPan = true //default false
// controls.noRotate = false //default false
// controls.noZoom = true //default false
// controls.staticMoving = false //default false
// controls.dynamicDampingFactor = 0.3
// controls.maxDistance = 4
// controls.minDistance = 2

// const controls = new PointerLockControls( camera, canvas )
const controls = new PointerLockControls( camera, document.body );
document.body.addEventListener( 'click', function () {
    controls.lock();
} );
// document.body.addEventListener( 'touchstart', function () {
//     controls.lock();
// } );
scene.add( controls.getObject() );

// controls.addEventListener( 'lock', function () {
// 	menu.style.display = 'none';
// } );

// controls.addEventListener( 'unlock', function () {
// 	menu.style.display = 'block';
// } );

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    alpha:false,
    antialias: false,
})

// renderer.setClearColor("#1e272e", 1.0);
// renderer.setClearColor("#000000", 1.0);
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

// renderer.physicallyCorrectLights = true;

renderer.shadowMap.enabled = true;
renderer.shadowMap.type = THREE.PCFSoftShadowMap;
// renderer.shadowMap.renderSingleSided = false;

/**
 * Post-processing
 */

// Post-processing inits
const composer = new EffectComposer(renderer);

// render pass
const renderPass = new RenderPass(scene, camera);

const renderTargetParameters = {
	minFilter: THREE.LinearFilter,
	magFilter: THREE.LinearFilter,
	stencilBuffer: false
};

// save pass
const savePass = new SavePass(
	new THREE.WebGLRenderTarget(
		canvas.clientWidth,
		canvas.clientHeight,
		renderTargetParameters
	)
);

// blend pass
const blendPass = new ShaderPass(BlendShader, "tDiffuse1");
blendPass.uniforms["tDiffuse2"].value = savePass.renderTarget.texture;
blendPass.uniforms["mixRatio"].value = motionBlurStrength; //0.8

// output pass
const outputPass = new ShaderPass(CopyShader);
outputPass.renderToScreen = true;

// glitch pass
const glitchPass = new GlitchPass();

// bloom pass
const bloomPass = new UnrealBloomPass( new THREE.Vector2( canvas.clientWidth, canvas.clientHeight ), 0, 0, 0 );
bloomPass.strength = 0.5;
bloomPass.radius = 0;
bloomPass.threshold = 0;

// adding passes to composer
composer.addPass(renderPass);
composer.addPass(blendPass);
composer.addPass(savePass);
composer.addPass(outputPass);
composer.addPass( glitchPass );
composer.addPass( bloomPass );


/**
 * Animate
 */

const clock = new THREE.Clock()

const tick = () =>
{

    const elapsedTime = clock.getElapsedTime()

    // Update objects
    // sphere.rotation.z = .5 * elapsedTime

    sphere.rotation.z = meshAccellarator * elapsedTime

    if(material.map){
        sphere.material.map.offset.y = elapsedTime*mapAccellarator;
    }
    if(material.emissiveMap){
        sphere.material.emissiveMap.offset.y = elapsedTime*mapAccellarator;
    }
    if(material.alphaMap){
        sphere.material.alphaMap.offset.y = elapsedTime*mapAccellarator;
    }
    if(material.lightMap){
        sphere.material.lightMap.offset.y = elapsedTime*mapAccellarator;
    }
    // console.log(typeof(controls))
    // controls.update()

    // Render
    // renderer.render(scene, camera)
    composer.render()

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()